export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: [Number, String],
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 组件ID
    moduleId: {
      default: 0,
      type: Number,
    },
  },
  methods: {
    /**
     * 组件按钮通用数据上报
     * @param {Object} buttonName 按钮名称
     */
    buttonTrackReport(options) {
      const { buttonName = '0' } = options || {}
      this.Sensors.track('component_button_click', {
        cms_component_type: this.componentType,
        cms_component_name: this.componentName,
        cms_component_title: this.componentTitle,
        cms_component_id: this.moduleId,
        click_type: 2, // 1: 卡片 2: 按钮
        button_name: buttonName,
        previous_source: this.$route.name,
      })
    },

    /**
     * 组件卡片通用数据上报
     * @param {Object} cardName 按钮名称
     */
    cardTrackReport(options) {
      const { cardName = '0' } = options || {}
      this.Sensors.track('component_button_click', {
        cms_component_type: this.componentType,
        cms_component_name: this.componentName,
        cms_component_title: this.componentTitle,
        cms_component_id: this.moduleId,
        click_type: 1, // 1: 卡片 2: 按钮
        card_name: cardName,
        previous_source: this.$route.name,
      })
    },

    /**
     * 卡片组件曝光通用数据上报
     * @param {Object} cardName 按钮名称
     */
    cardshowTrackReport(options) {
      const { cardName = '0' } = options || {}
      return {
        cms_component_type: this.componentType,
        cms_component_name: this.componentName,
        cms_component_title: this.componentTitle,
        cms_component_id: this.moduleId,
        card_name: cardName,
        previous_source: this.$route.name,
      }
    },
  },
}
