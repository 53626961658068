export default {
  props: {
    // 组件名称
    componentName: {
      default: 'GetNotified2',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 组件id
    moduleId: {
      default: '',
      type: String,
    },
    // 分校识别码
    locale: {
      default: '',
      type: String,
    },
    // 标题
    title: {
      default: '',
      type: String,
    },
    // 简介
    intro: {
      default: '',
      type: String,
    },
    // 按钮文案
    submit: {
      default: 'Submit',
      type: String,
    },
    /**
     * 表单字段名称配置
     */
    clue: {
      default: '',
      type: String,
    },
    host: {
      default: '',
      type: String,
    },
    secret: {
      default: '',
      type: String,
    },
    // 字段列表
    dataList: {
      default: () => ([]),
      type: Array,
    },
    // 配置来源类型
    type: {
      default: 'MODULE',
      type: String,
    },
  },
  data() {
    const config = this.getConfig()
    return {
      hasFirst: false,
      submitName: config.submitName,
      agreementNotice: config.agreementNotice,
      successNotice: config.successNotice,
      errorNotice: config.errorNotice,
      phoneError: config.phoneError,
      requiredError: config.requiredError,
      emailError: config.emailError,
      rules: config.rules,
      loading: false,
      phoneCodeList: this.$store.state.phoneCodeList,
      phoneAreaCode: {},
      formData: {
        clue: '',
        host: '',
        secret: '',
        subStatus: true,
        emailShow: false,
        dataList: [],
      },
    }
  },
  computed: {
    gradeList() {
      const gradeListData = []
      this.dataList.forEach(e => {
        if (e.key == 'gradeId' && e.classList && e.classList.length > 0) {
          e.classList.forEach((i) => {
            if (i.show) {
              gradeListData.push(i)
            }
          })
        }
      })
      if (gradeListData) { }
      return gradeListData
    },
  },
  methods: {
    async init() {
      const gradeList = this.$store.state.courses.gradeList || []
      // 初始化年级数据
      if (Array.isArray(gradeList) && gradeList.length === 0) {
        await this.$store.dispatch('courses/queryGradeList', { $axios: this.$axios })
      }
      // 初始化手机区号对象
      const initPhoneAreaCode = this.$store.state.defaultPhoneCode || this.phoneCodeList[0]
      this.phoneAreaCode = initPhoneAreaCode
      this.handleAreaCodeChange()
    },

    initFormData() {
      const webClient = process.env.clientType === 'web'
      const dataList = []
      this.hasFirst = false
      this.dataList.forEach((item) => {
        const fieldData = {
          ...item,
          value: '',
          valueName: '', // touch端输入框回显已选择下拉列表数据
          checked: [], // touch端多选
          rules: [{
            required: !!item.required,
            message: this.requiredError,
            trigger: webClient ? 'blur' : 'onBlur',
          }],
        }
        if (item.optionsType === 'select' || item.optionsType === 'selectMore') {
          fieldData.showPopup = false
        }
        if (item.show) {
          if (item.key === 'email' && item.custom === false) {
            this.formData.emailShow = true
          }
          if (item.key === 'firstName' || item.key === 'lastName' || item.key === 'givenName' || item.key === 'familyName') {
            this.hasFirst = true
          }
          if (item.key === 'fullName') {
            this.hasFirst = true
            dataList.push({
              key: 'givenName',
              name: item.name[0],
              show: item.show,
              optionsType: item.optionsType,
              custom: item.custom,
              index: item.index,
              required: item.required,
              def: item.def,
              value: '',
              valueName: '', // touch端输入框回显已选择下拉列表数据
              checked: [], // touch端多选
              rules: [{
                required: !!item.required,
                message: this.requiredError,
                trigger: webClient ? 'blur' : 'onBlur',
              }],
            })
            dataList.push({
              key: 'familyName',
              name: item.name[1],
              show: item.show,
              optionsType: item.optionsType,
              custom: item.custom,
              index: item.index,
              required: item.required,
              def: item.def,
              value: '',
              valueName: '', // touch端输入框回显已选择下拉列表数据
              checked: [], // touch端多选
              rules: [{
                required: !!item.required,
                message: this.requiredError,
                trigger: webClient ? 'blur' : 'onBlur',
              }],
            })
          } else {
            dataList.push(fieldData)
          }
        }
      })
      const orderList = dataList.sort((a, b) => a.index - b.index)
      this.formData.dataList = orderList
    },

    getDataParamsList() {
      let dataParamsList = []
      try {
        this.formData.dataList.forEach((item) => {
          let value = ''
          if (item.optionsType === 'input') {
            if (item.key === 'phone' && item.custom === false) {
              value = item.value
              if (item.value !== '') {
                dataParamsList.push({
                  key: 'areaCode',
                  value: this.phoneAreaCode.value,
                  custom: false,
                })
              }
            } else {
              value = item.value
            }
          }
          if (item.optionsType === 'select') {
            if (item.value !== '') {
              value = item.options[item.value].name
            }
          }
          if (item.optionsType === 'selectMore') {
            // 判断多选无值时
            if (item.value !== '') {
              const tempList = []
              item.value.forEach((option) => {
                tempList.push(item.options[option].name)
              })
              const preValue = tempList.join(',')
              value = JSON.stringify(preValue.split(',')).replace(/"/g, '\"')
            }
          }
          dataParamsList.push({
            // 自定义字段key传name
            key: item.key || undefined,
            name: item.name,
            value,
            custom: item.custom,
            multi: item.optionsType === 'selectMore',
          })
        })
      } catch (error) {
        console.error(error)
        dataParamsList = []
      }
      return dataParamsList
    },

    /**
     * 获取文案配置
     */
    getConfig() {
      const { locale } = this.$store.state
      const requiredError = this.$t('featureComponents.page.tips.invalid')
      const emailError = this.$t('featureComponents.page.getNotifiedConfig.emailError')
      const phoneError = this.$t('featureComponents.page.getNotifiedConfig.phoneError')
      const submitName = this.$t('featureComponents.page.getNotifiedConfig.submit')
      const agreementNotice = this.$t('featureComponents.page.getNotifiedConfig.notice')
      let successNotice = this.$t('featureComponents.page.getNotifiedConfig.success')
      const errorNotice = this.$t('featureComponents.page.getNotifiedConfig.errorNotice')
      if (locale === 'tmc') {
        successNotice = 'Thank you for your interest in Mathink Club!'
      }
      if (locale === 'tm') {
        successNotice = 'Thank you for your interest in Think Matrix!'
      }
      if (locale === 'bmus' || locale === 'bmsg') {
        successNotice = 'Thank you for your interest in Better Me!'
      }
      return {
        // 提交按钮名称
        submitName,
        // 协议提示
        agreementNotice,
        // 提交成功提示
        successNotice,
        // 提交失败提示
        errorNotice,
        // 手机号错误提示
        phoneError,
        // 输入错误提示
        requiredError,
        // 邮箱号错误提示
        emailError,
      }
    },
  },
}
