<template>
  <el-dialog
    :visible="visible"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="800px"
    custom-class="dialog-video"
  >
    <i class="el-icon-close" @click="handleClose" />
    <div v-if="videoOptions.sources[0].src" class="video-wrapper">
      <video-player ref="videoPlayer" :options="videoOptions" class="vjs-theme-fantasy" />
    </div>
  </el-dialog>
</template>

<script>
import VideoPlayer from '../VideoPlayer/index';

export default {
  components: {
    VideoPlayer,
  },
  data() {
    return {
      visible: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        playsinline: true,
        width: 800,
        sources: [
          {
            src: '',
            type: 'video/mp4',
          },
        ],
      },
    }
  },
  methods: {
    handleOpen(url) {
      this.videoOptions.sources[0].src = url;
      this.visible = true;
      setTimeout(() => {
        this.$refs.videoPlayer.play();
      }, 100);
    },
    handleClose() {
      this.visible = false
      this.videoOptions.sources[0].src = ''
    },
  },
}
</script>

<style lang="scss">
.dialog-video {
  border-radius: 10px;
  box-shadow: none;

  .el-icon-close {
    display: flex;
    position: absolute;
    right: -35px;
    top: -25px;
    width: 34px;
    height: 34px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    opacity: 0.6;
    cursor: pointer;

    &:hover {
      opacity: 1;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .video-wrapper {
    position: relative;
    width: 800px;
    overflow: hidden;
    border-radius: 10px;
  }
}
</style>
