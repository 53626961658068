<template>
  <div class="evaluation-dialog">
    <el-dialog
      v-if="show"
      :title="evaluationDialogInfo.title"
      :show-close="false"
      :visible.sync="show"
      width="610px"
      custom-class="evaluaDialog"
      top="15%"
    >
      <span>{{ evaluationDialogInfo.message }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goEvaluate(evaluationDialogInfo.res)">{{ evaluationDialogInfo.confirmButtonText }}</el-button>
        <el-button v-if="evaluationDialogInfo.showCancel" @click="closeHander">{{ $t('featureComponents.page.tips.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    evaluationDialogInfo: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closeHander() {
      // 通知父级更新弹出层显示状态
      this.$emit('update:show', false);
    },
    goEvaluate(res) {
      const status = res.helper.status || ''
      if (status === 1) {
        if (this.$store.state.locale === 'us') {
          this.$router.push('/1-to-1-assessment');
          return;
        }
        if (res.helper.extra.goExamUrl.includes('?')) {
          window.open(`${res.helper.extra.goExamUrl}&utoken=${res.helper.extra.utoken}`, '_blank')
        } else {
          window.open(`${res.helper.extra.goExamUrl}?utoken=${res.helper.extra.utoken}`, '_blank')
        }
        this.$emit('update:show', false);
        return
      }

      this.$emit('update:show', false);
    },
  },
}
</script>
<style lang="scss">
  .evaluation-dialog {
    .el-dialog__body {
      line-height: 24px;
      font-size: 14px;
      word-break: break-word;
      padding-top: 0;
    }

    .el-dialog__header {
      font-size: 18px;
      padding-top: 40px;
      padding-bottom: 20px;
      font-weight: bold;
    }

    .evaluaDialog {
      border-radius: 16px;
    }
  }
</style>
